import styled from "@emotion/styled";
import Accordion from "../../shared/components/Accordion/Accordion";
import { lightBg } from "../../shared/styles/colors";
import { desktopPadding, mobilePadding } from "../Home";
import { useDispatch, useSelector } from "react-redux";
import { shouldRedirectToNewFlow } from "../../shared/utils/helper.utils";
import { useEffect } from "react";
import { setReferral } from "../../slices/tracker.slice";

const oldFaqs = [
  {
    id: 1,
    title: "What does OPD mean?",
    content:
      "OPD means Outpatient Department.It includes medicine orders, lab tests, doctor consultations, dental care and more. OPD means you don’t need hospital admission for over 24 hours.",
  },
  {
    id: 2,
    title: "Is there any grace period after the plan has expired?",
    content:
      "Once your plan has expired, you get 5 days worth of grace period. You can renew your plan in these five days to continue enjoying your benefits.",
  },
  {
    id: 3,
    title: "What happens when I don’t renew my plan?",
    content:
      "Your subscription is paused and you won’t be able to use your health benefits.",
  },
  {
    id: 4,
    title: "How do I renew plans?",
    content:
      "If your plan is expiring soon, you’ll see a button on the dashboard that asks you to renew your plan. Select the tenure you wish to renew your plan with and that’s all you need to do.",
  },
  {
    id: 5,
    title: "Do you accept cash payments?",
    content:
      "Sorry, all payments made to Kenko have to be digital transactions. We will send you a copy of the invoice for all transactions.",
  },
];

const newFaqs = [
  {
    id: 1,
    title: "What is Kenko?",
    content:
      "Kenko is a tech based healthcare company that offers comprehensive discounts and benefits, including discounted OPD services, and exclusive discounts on everyday care items through its online store (SHOHO) . Kenko’s mission is to provide simple, accessible and affordable healthcare solutions to individuals, ensuring financial security and peace of mind.",
  },
  {
    id: 2,
    title: "What is covered under OPD benefits?",
    content:
      "OPD benefits primarily include doctor consultation, prescribed diagnostics and prescribed medicines. The benefits may vary according to the plan you select.",
  },
  {
    id: 3,
    title: "How can I avail OPD benefits?",
    content:
      "Doctor consultation: \n     1.  In-person doctor visit \n         a.  Visit any doctor in India \n         b.  Upload bill on the Kenko app \n         c.  Get a discount as per chosen plan on the bill amount \n\n    2.  Tele-consultation \n         a.  Book a tele consultation with doctors available on the Kenko app \n         b.  Get a discount as per chosen plan on the bill amount      \n\n\nPrescribed medicines: \n     1.  Upload doctor’s prescription & itemised bill on the Kenko app \n    2.  Get a discount as per chosen plan on the bill amount \n    3.  Place an order on the app & get it delivered to your doorstep \n\n\nPrescribed Diagnostics: \n     1.  Upload doctor’s prescription on the Kenko app \n    2.  Schedule at home sample pickup from the app \n    3.  Get a discount as per chosen plan on the bill amount",
  },
  {
    id: 4,
    title: "What is the benefit under the “OPD in Hospitalisation” benefit?",
    content: (
      <p>
        OPD in Hospitalisation is defined as the OPD expenses part of a hospital
        treatment bill, i.e. all expenses which can be classified as OPD such as{" "}
        <b>doctor consultation, diagnostics, medicines</b> used during the
        course of daycare or long term treatment at the hospital.
        <br />
        <br />
        Kenko plans currently offer this benefit up to the limit defined in the
        plan in case of hospitalisation due to a natural disaster (non COVID)
        such as fire, earthquakes, etc. The benefits can be availed in the same
        manner as OPD process.
      </p>
    ),
  },
  {
    id: 5,
    title: "What is SHOHO?",
    content:
      "SHOHO is Kenko’s one-stop shop for all your wellness essentials and everyday needs with a vast inventory of 3 lakh+ items (like shampoo, diapers, sanitary napkins, etc.), delivered right to your doorstep. \n\nWe cover 26,000+ pin codes across India.",
  },
  {
    id: 6,
    title: "How can I avail benefits on the SHOHO?",
    content:
      "    1.  Choose products you need everyday\n    2.  Add items to cart\n    3.  Settle the bill through the app and get discount as per chosen plan on the bill amount\n    4.  Get the items delivered at your doorstep",
  },
  {
    id: 7,
    title: "Can I start using Kenko Benefits from Day 1?",
    content:
      "Subscription activation will take place after verification call and a free medical checkup ( if applicable). Post activation, benefits will be payable based on the completion of the below mentioned Cooling Periods \n    - OPD benefits - 15 Days \n    - Hospitalisation benefits - As  per the IRDAI licensed Insurance Company Terms",
  },
  {
    id: 8,
    title: "What is cooling period?",
    content:
      "Cooling period is the time period during which benefit will not be payable by Kenko or IRDAI Licensed Insurance partner.",
  },
  {
    id: 9,
    title: "How do I get the doctor’s prescription after Tele-consult?",
    content:
      "You can download the doctor’s prescription from the app once tele-consult is complete.",
  },
  {
    id: 10,
    title: "Would Kenko provide benefits for my family?",
    content:
      "Yes you can add your spouse and up to 2 children to the subscription so that benefits will apply to them as well. Subscription charges vary based on the number of family members added.",
  },
  {
    id: 11,
    title: "Can I buy a Kenko subscription if I have pre-existing diseases?",
    content:
      "Yes, you can buy a Kenko subscription if you have pre-existing diseases but we have a cooling period of 2 years for such diseases.",
  },
  {
    id: 12,
    title:
      "Is a medical checkup necessary before I can start availing my plan benefits?",
    content:
      "When buying a Kenko subscription, you will be asked to accept a health declaration. It is essential that you read the declaration in depth to ensure that we can provide the necessary care once you subscribe. \n\nIn certain cases, a health checkup, additional investigations or tests may be required before you can start availing the subscription benefits. This is done to assess your health status for us to provide you the most appropriate healthcare services and customised solutions. Please note that failure to disclose accurate information in the health declaration or during the medical test may result in the termination of your subscription. Also, in case the health checkup or test results indicate your health status being critically close to violating the declaration provided by you, Kenko would be unable to provide you benefits and hence reserves the right to terminate the subscription.",
  },
  {
    id: 13,
    title: "What is the age limit for getting a Kenko subscription?",
    content:
      "Kenko subscription is available for purchase to people in the age group of 18-55 years (inclusive).",
  },
  {
    id: 14,
    title: "How do I use Kenko in case of an emergency?",
    content:
      "We have an Emergency Button available on the App that you can use to inform our team that you are in need of emergency assistance. Once you click on the button, our team will call you immediately to address your concern and help you in the best way possible.\nAlternatively, you can call our emergency helpline number +91-91138-39585.",
  },
  {
    id: 15,
    title: "How do I cancel my Kenko subscription? What are the refund terms?",
    content: (
      <p>
        Customers can cancel the subscription and are eligible for a refund if
        the following conditions are met:
        <br />
        &nbsp;&nbsp; - The request for a refund is made within 7 days of the
        subscription purchase date
        <br />
        &nbsp;&nbsp; - No benefits have been used by the customer during the
        benefit period <br />
        <br />
        For cancellation and refund requests, customers must contact our
        customer service at 1800-843-3599 within the specified 7-day period. Our
        dedicated representatives will guide you through the process and assist
        you with any queries you may have. <br />
        Know more about our refund and cancellation policy{" "}
        <a
          href="https://kenkohealth.in/refund-&-cancellation"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline" }}
        >
          here
        </a>
        .
      </p>
    ),
  },
];

export const referralFaqs = [
  {
    id: 1,
    title: "How can I refer someone to Kenko?",
    content:
      "You can only refer someone to Kenko if you have an active Kenko subscription. Your Kenko registered mobile number is your referral code. Anyone who you want to introduce Kenko to, needs to sign up (on Kenko app or website) using your referral code to get counted as your referral.",
  },
  {
    id: 2,
    title: "Is there a limit to how many people I can refer to Kenko?",
    content: (
      <p>
        There is no limit to how many people you can invite to Kenko. The
        maximum referral reward you can win is <b> ₹8,00,000</b> in a year.
        After this limit you don’t get any rewards for inviting someone new to
        Kenko. There will be a 10% TDS deduction once you cross ₹30,000 in
        rewards.
      </p>
    ),
  },
  {
    id: 3,
    title: "Do I have to sell Kenko to win reward?",
    content:
      "NO. You ONLY need to let someone know about your Kenko experience and offer them to sign up using your referral code. \nOnce they sign up using your referral code, Kenko’s team will get in touch with them to explain the subscription benefits and assist them in their purchase using your referral code. \n\nWe reward you for introducing us to someone who needed Kenko. We take it from there.",
  },
  {
    id: 4,
    title: "What benefit will someone get on using my referral code?",
    content:
      "FLAT 25% OFF on the subscription amount. This discount won’t be applicable for someone who is renewing a Kenko subscription.",
  },
  {
    id: 5,
    title: "How will I receive my referral reward amount?",
    content:
      "We credit the money straight to your desired bank account. We will send you a link where you can enter your bank/UPI details in which you want the reward credited.",
  },
  {
    id: 6,
    title: "How much time will it take for me to recieve the reward?",
    content:
      "We pay you after 7 days of a member subscribing to Kenko. \nWe offer a 7 day no questions asked 100% refund policy in case of cancellations. So the member you invite gets a 7 day period to cancel their Kenko subscription in case they are unsure and get their money back. Referral rewards are given only against referred members who don’t end up cancelling their subscription during the 7 day period.",
  },
  {
    id: 7,
    title: "Is there an age limit for someone I can refer Kenko to?",
    content:
      "Anyone between the age of 18-55 are eligible to buy a Kenko subscription for themselves and their family and thus is eligible for your referral.",
  },
  {
    id: 8,
    title: "How can I refer a lot of people quickly?",
    content:
      "We believe every individual and family should have a Kenko subscription for their financial safety. Thus, anyone you feel who has not take a step towards their health’s financial safety is someone you should refer Kenko to. \n\nIn our opinion, \n- newly married couples, \n- new parents, \n- young individuals who have just started their professional career \n\ntend to love experiencing Kenko subscriptions and thus are the kind of folks you can refer Kenko to. \n\nWhile sharing amongst your relatives, friends, neighbours and office colleagues is helpful, we do recommend you have a one on one conversation with someone when you tell them about Kenko (and not just spam them). Share your experience of how you find Kenko a great investment towards a more secure and healthier life. This might encourage them to think through their requirements and choose a suitable Kenko subscription for themselves.",
  },
  {
    id: 9,
    title: "How can I track my referrals?",
    content:
      "We will keep you posted on your registered phone number via Whatsapp.",
  },
];

export const faqQuestions = (
  newFlow: boolean,
  referral: boolean,
  isReferral?: boolean
) => {
  if (referral || isReferral) return referralFaqs;
  else if (newFlow) return newFaqs;

  return oldFaqs;
};

const FaqListWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px;
  border-radius: 20px;
  background-color: ${lightBg};
  max-width: 1536px;
  width: 70%;
  margin: 30px ${desktopPadding} ${desktopPadding};

  @media (max-width: 600px) {
    margin: ${mobilePadding};
    padding: 20px;
    width: 90%;
  }
`;

const FaqList = () => {
  const dispatch = useDispatch();
  const { isReferral } = useSelector((state: any) => state.tracker);
  const utm = useSelector((state: any) => state.utmReducer);
  const newFlow = shouldRedirectToNewFlow(utm);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      dispatch(setReferral(false));
    });

    window.addEventListener("unload", () => {
      dispatch(setReferral(false));
    });
  }, [dispatch]);

  return (
    <FaqListWrapper>
      <Accordion items={faqQuestions(newFlow, false, isReferral)} />
    </FaqListWrapper>
  );
};

export default FaqList;
