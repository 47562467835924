import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Typography from "../Typography/Typography";
import Paper from "../Paper/Paper";
import * as ga from "../../../components/common/UtilsGA";
import {
  KeyboardArrowUpRounded,
  KeyboardArrowDownRounded,
} from "@mui/icons-material";
import { useRouter } from "../../hooks";

const AccordionWrapper = styled.div`
  width: 100%;

  .accordian {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 35px;

    @media (max-width: 600px) {
      gap: 20px;
    }
  }

  .accordian .accordian-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    padding: 19px 30px;
    border-radius: 8px;
    color: var(--kenkoDeepTeal);

    @media (max-width: 600px) {
      padding: 15px 20px;
    }
  }

  .accordian .active-tab {
    border: 1px solid #e9e9e9;
    background-color: var(--kenkoPureWhite);
  }

  .accordian .inactive-tab {
    border: 1px solid #569091;
  }

  .accordian .tab-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    opacity: 0.87;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 20px;
      align-items: flex-start;
    }
  }

  .accordian .tab-content {
    opacity: 0.6;
    display: none;
    line-height: 26px;
    transition: all 0.5s ease;
    font-size: 18px;
    max-width: 90%;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 16px;
      max-width: 85%;
    }
  }

  .accordian .active-tab .tab-content {
    display: block;
    transition: all 0.5s ease;
    white-space: pre-wrap;
  }

  .accordian .accordian-icon {
    font-size: 40px;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
`;

export default function Accordion({ items, className, style }: any) {
  const [tabs, setTabs] = useState(
    items.map((item: any) => ({ ...item, isActive: false }))
  );
  const router = useRouter();

  useEffect(() => {
    // Limit FAQs to 5 except on /faqs page
    if (tabs?.length > 5 && router?.asPath !== "/faq") {
      setTabs(() => items?.slice(0, 5));
    }
  }, [items, router.asPath]);

  return (
    <AccordionWrapper>
      <Paper
        elevation={0}
        className={["accordian", className && className].join(" ")}
        style={style}
      >
        {tabs.map((tab: any) => (
          <div
            key={tab?.id}
            className={[
              "accordian-tab",
              tab.isActive ? "active-tab" : "inactive-tab",
            ].join(" ")}
          >
            <div
              className="tab-title"
              onClick={() => {
                setTabs((prev: any) =>
                  prev.map((item: any) =>
                    item.id === tab.id
                      ? { ...item, isActive: !item.isActive }
                      : { ...item, isActive: false }
                  )
                );
                ga.event({
                  action: "web_faq_question_clicked",
                  params: {
                    Page_Url: window.location.href,
                    Question: tab.title,
                  },
                });
                window?.Moengage?.track_event("web_faq_question_clicked", {
                  Page_Url: window.location.href,
                  Question: tab.title,
                });
              }}
            >
              <Typography>{tab.title}</Typography>
              {tab.isActive ? (
                <KeyboardArrowUpRounded className="accordian-icon" />
              ) : (
                <KeyboardArrowDownRounded className="accordian-icon" />
              )}
            </div>
            <div className="tab-content">
              <Typography>{tab.content}</Typography>
            </div>
          </div>
        ))}
      </Paper>
    </AccordionWrapper>
  );
}

Accordion.propTypes = {
  /**
   * The array of items for the component
   */
  items: PropTypes.array.isRequired,
};
